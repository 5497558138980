export const SlideBars = [
  {
    title: 'Home',
    path: '/home#about=',
    cName: 'navText'
  },
  // {
  //   title: 'Dev Projects',
  //   path: '/home#devprojects',
  //   cName: 'navText'
  // },
  {
    title: 'Portfolio',
    path: '/home#portfolio',
    cName: 'navText'
  },
  {
    title: 'Resume',
    path: '/home#resume',
    cName: 'navText'
  },


 
]