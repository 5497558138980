import React from "react"
const Footer = () => {
    return (
        <div className='footer'>
            <p>Designed & Built By Madeline Armstrong</p>
        </div>

    );
};


export default Footer;